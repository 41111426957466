import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Injector, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';
import { EventManagerService } from '../service/event-manager.service';


@Injectable({ providedIn: 'root' })
export abstract class EditComponent implements OnInit, OnDestroy {
  @Input() queryParams: any = {};
  @Input() id: any;
  editForm: any;
  @Input() detailObject: any = {};
  errors: any = {};
  isSaved: boolean = false;
  isCalling: boolean = false;
  isUpload: boolean = false;
  subscriptions?: Subscription[] = [];
  subsCommon?: Subscription[] = [];
  callApi$ = new Subject();
  checkChange$ = new Subject();
  infoForm: any;
  constructor(
    public eventManager: EventManagerService,
    public route: ActivatedRoute
  ) {
  }
  ngOnInit() {
    const params = this.route.snapshot.params;
    this.id = params.id;
    if (this.id) {
      this.getDetail();
    }
    this.subsCommon =[
      this.eventManager.subscribe('upload-file', (res) => {
        this.isUpload = true;
      }),
      this.eventManager.subscribe('upload-fail', (res) => {
        this.isUpload = true;
      }),
      this.callApi$.pipe(
        debounceTime(500),
        ).subscribe((event)=> { return this.callAPI(event);}),
      this.checkChange$.pipe(
        debounceTime(500),
        ).subscribe((event)=> { return this.checkChange();}),
    ];
  
  }
  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.eventManager.destroys(this.subscriptions);
    }
    this.eventManager.destroys(this.subsCommon);
  }
  getDetail() {}
  onSuccess(res) {}
  onError(error) {}
  updateForm() {}
  disabledButton() {
    const keys = Object.keys(this.errors);
    const key = keys.find(key => this.errors[key]);
    return key ? true : false;
  }
  callAPI(event?) {
    this.save();
  }
  checkChange() {}
  executeCallAPI(event?) {
    this.callApi$.next(event || true);
  }
  patchValue(key, value) {
    this.editForm.patchValue({
      [key]: value
    })
  }
  patchForm(key, value) {
    this.editForm.patchValue({
      [key]: value
    })
  }
  beforeSave() {
    this.isSaved =  true;
    const values = this.infoForm.value;
    Object.keys(values).forEach((key) => {
      if (values[key] === 'undefined') {
        const object = {};
        object[key] = undefined;
        this.infoForm.patchValue(object);
      }
    })
    const result = this.disabledButton();
    if (result || this.infoForm.invalid) {
      return true;
    }
  }
  save() {
    if (this.beforeSave()) {
      return;
    }
  }
}
